import React from 'react'

export default function Individuals() {
    return (
        <div className='flex flex-col'>
            <div className="relative isolate">
                <img
                    src="/solutions/individuals-header.png"
                    alt=""
                    className="absolute -z-10 object-cover h-full w-full inset-0 transition-all duration-500"
                />
                <div className="py-32 mx-auto flex flex-col max-w-7xl px-6 lg:px-8">
                    <div className='text-white'>
                        Welcome to HorizonPredict
                    </div>
                    <div className="my-5 space-y-3">
                        <div className="text-3xl font-bold text-white max-w-sm leading-normal">
                            Your ultimate partner in transformative cost <el className='text-red-500'>management solutions</el>.
                        </div>
                    </div>
                    <div className='text-white max-w-lg'>
                        Here at HorizonPredict, we are dedicated to empowering businesses, entrepreneurs, hobbyists, and individuals with advanced tools to understand and optimize their financial operations down to the finest details.
                    </div>
                    <div className="px-5 py-2 bg-red-500 w-fit mt-12 rounded-xl text-white">
                        <a href="/sign-up" className="no-underline text-white">SIGN UP</a>
                    </div>
                </div>
            </div>
            <div className="bg-white">
                <div className="py-16 mx-auto flex flex-col max-w-7xl px-6 lg:px-8">
                    <div className="overflow-hidden">
                        <div className="relative mx-auto max-w-7xl py-8 px-5 lg:px-8">
                            <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
                                <div className={`relative lg:row-start-1 lg:col-start-1`}>
                                    <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
                                        <img
                                            className="rounded-lg object-contain w-full"
                                            src='/solutions/individuals.png'
                                            alt=""
                                            loading='lazy'
                                        />
                                    </div>
                                </div>
                                <div className="mt-8 lg:mt-0">
                                    <div className='mx-auto max-w-prose lg:p-10'>
                                        <div className="text-3xl font-bold mt-5">
                                            Lorem ipsum dolor sit <br /><el className='text-red-500'>amet consectetur</el>
                                        </div>
                                        <div className="mt-8">
                                            Lorem ipsum dolor sit amet consectetur. Tortor tincidunt lorem ac libero arcu quis. Velit vel neque ultrices mauris ultricies erat risus adipiscing. Viverra venenatis congue tellus dolor consectetur. In eget amet aenean ipsum quam tortor tortor. Lorem ipsum dolor sit amet consectetur. Tortor tincidunt lorem ac libero arcu quis. Velit vel neque ultrices mauris ultricies erat risus adipiscing. Viverra venenatis congue tellus dolor consectetur. In eget amet aenean ipsum quam tortor tortor. Lorem ipsum dolor sit amet consectetur. Tortor tincidunt lorem ac libero arcu quis. Velit vel neque ultrices mauris ultricies erat risus adipiscing. Viverra venenatis congue tellus dolor consectetur. In eget amet aenean ipsum quam tortor tortor. Lorem ipsum dolor sit amet consectetur. Tortor tincidunt lorem ac libero arcu quis. Velit vel neque ultrices mauris ultricies erat risus adipiscing. Viverra venenatis congue tellus dolor consectetur. In eget amet aenean ipsum quam tortor tortor.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
