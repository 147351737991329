import { combineReducers } from '@reduxjs/toolkit';

import counterReducer from './counterSlice';
import { horizonPredictApi } from '../apis/horizonPredict';

const rootReducer = combineReducers({
  counter: counterReducer,
  [horizonPredictApi.reducerPath]: horizonPredictApi.reducer,
});

export default rootReducer;
