import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// Create a theme instance.
const theme = createTheme({
    typography: {
        fontFamily: "'Public Sans', sans-serif",
    },
    components: {
        MuiCssBaseline: {
        styleOverrides: `
            @font-face {
                font-family: "'Public Sans', sans-serif";
            }
        `,
        },
    },
    palette: {
        primary: {
            main: '#3999A8',
            contrastText: "#fff"
        },
            secondary: {
            main: '#19857b',
        },
        third: {
            main: '#79A9AF',
        },
        disabled: {
            main: '#bdbdbd',
        },
        orange: {
            main: '#fbb042',
        },
        error: {
            main: red.A400,
        },
    },
});

export default theme;
