import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { horizonPredictApi } from './apis/horizonPredict';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(horizonPredictApi.middleware),
});

export default store;
