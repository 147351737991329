import { 
    useRef, 
    useState 
} from "react";

import { 
    Accordion, 
    AccordionDetails, 
    AccordionSummary, 
    Typography,
    styled
} from "@mui/material";

import { useIsVisible } from "../../hooks/useIsVisible";
import { ExpandMore } from "@mui/icons-material";

const StyledAccordion = styled((props) => (
    <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
        borderLeft: 0,
        borderRight: 0
    },
    '&:is(:last-child)': {
        borderLeft: 0,
        borderRight: 0
    },
    '&::before': {
        display: 'none',
    },
}));

export default function Home() {
    const solutionRef = useRef();
    const isVisibleSolution = useIsVisible(solutionRef);

    const benefitRef = useRef();
    const isVisibleBenefit = useIsVisible(benefitRef);

    const useCaseRef = useRef();
    const isVisibleUseCase = useIsVisible(useCaseRef);

    const experienceRef = useRef();
    const isVisibleExperience = useIsVisible(experienceRef);

    const [accordionExpanded, setAccordionExpanded] = useState('businesses');

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setAccordionExpanded(panel);
    };

    return (
        <div className='flex flex-col'>
            <div className="relative isolate">
                <img
                    src="home/header.png"
                    alt=""
                    className="absolute -z-10 h-full w-full object-cover inset-0 transition-all duration-500"
                />
                <div className="py-32 mx-auto flex flex-col max-w-7xl px-6 lg:px-8">
                    <div className='text-white'>
                        Welcome to HorizonPredict
                    </div>
                    <div className="my-5 space-y-3">
                        <div className="text-3xl font-bold text-white max-w-sm leading-normal">
                            Your ultimate partner in transformative cost <el className='text-red-500'>management solutions</el>.
                        </div>
                    </div>
                    <div className='text-white max-w-lg'>
                        Here at HorizonPredict, we are dedicated to empowering businesses, entrepreneurs, hobbyists, and individuals with advanced tools to understand and optimize their financial operations down to the finest details.
                    </div>
                    <div className="px-5 py-2 bg-red-500 w-fit mt-12 rounded-xl">
                        <a href="/sign-up" className="no-underline text-white">SIGN UP</a>
                    </div>
                </div>
            </div>
            <div className="relative">
                <img
                    src="home/bg-mask.png"
                    alt=""
                    className="absolute -z-10 h-full w-full object-cover inset-0"
                />
                <div className="py-16 mx-auto flex flex-col max-w-7xl px-6 lg:px-8">
                    <div className="overflow-hidden">
                        <div className="relative mx-auto max-w-7xl py-8 px-6 lg:px-8">
                            <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
                                <div className={`relative lg:row-start-1 lg:col-start-1`}>
                                    <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
                                        <img
                                            className={`rounded-lg object-contain w-full ${isVisibleSolution ? 'animate-fade-right' : ''}`}
                                            src='home/group-481.png'
                                            alt=""
                                            loading='lazy'
                                            ref={solutionRef}
                                        />
                                    </div>
                                </div>
                                <div className="mt-8 lg:mt-0">
                                    <div className='mx-auto max-w-prose'>
                                        <div className="text-red-500 font-medium">
                                            Our Solution                                        
                                        </div>
                                        <div className="text-3xl font-bold mt-5">
                                            Start Smart. Start Confident. Start with HorizonPredict.
                                        </div>
                                        <div className="flex">
                                            <img
                                                className="rounded-lg object-contain w-10 h-10 mt-5"
                                                src='home/fi_9413197.png'
                                                alt=""
                                                loading='lazy'
                                            />
                                            <div className="p-5">
                                                <div className="font-bold text-xl">
                                                    Comprehensive Cost Analysis
                                                </div>
                                                <div className="mt-3">
                                                    Dive deep into your expenses with our robust cost analysis tools. Understand where every penny goes and identify opportunities to reduce costs and increase efficiency.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <img
                                                className="rounded-lg object-contain w-10 h-10 mt-5"
                                                src='home/fi_11518892.png'
                                                alt=""
                                                loading='lazy'
                                            />
                                            <div className="p-5">
                                                <div className="font-bold text-xl">
                                                    Inventory Management
                                                </div>
                                                <div className="mt-3">
                                                    Keep your inventory in check with our intuitive management system. Whether you run a small business or manage personal projects, our tools help you stay organized and on top of your stock levels.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pb-16 mx-auto flex flex-col max-w-7xl px-6 lg:px-8">
                    <div className="overflow-hidden">
                        <div className="relative mx-auto max-w-7xl py-8 px-6 lg:px-8">
                            <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
                                <div className={`relative lg:row-start-1 lg:col-start-2`}>
                                    <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
                                        <img
                                            className={`rounded-lg object-contain w-full ${isVisibleBenefit ? 'animate-fade-left' : ''}`}
                                            src={` home/${accordionExpanded}.png`}
                                            alt=""
                                            loading='lazy'
                                            ref={benefitRef}
                                        />
                                    </div>
                                </div>
                                <div className="mt-8 lg:mt-0">
                                    <div className='mx-auto max-w-prose'>
                                        <div className="text-3xl font-bold mt-5">
                                            Who Can Benefit?
                                        </div>
                                        <StyledAccordion 
                                            expanded={accordionExpanded === 'businesses'} 
                                            onChange={handleAccordionChange('businesses')}
                                            className="bg-transparent mt-8"
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMore />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                            >
                                                <Typography 
                                                    variant="h6"
                                                    className={`${accordionExpanded === 'businesses' ? 'text-red-500' : ''}`}
                                                >
                                                    Small Businesses
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    Perfect for small enterprises looking to streamline their operations. Our tools help you manage your costs and inventory, allowing you to focus on growing your business.
                                                </Typography>
                                            </AccordionDetails>
                                        </StyledAccordion>
                                        <StyledAccordion 
                                            expanded={accordionExpanded === 'entrepreneurs'} 
                                            onChange={handleAccordionChange('entrepreneurs')}
                                            className="bg-transparent"
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMore />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                            >
                                                <Typography 
                                                    variant="h6"
                                                    className={`${accordionExpanded === 'entrepreneurs' ? 'text-red-500' : ''}`}
                                                >
                                                    Entrepreneurs
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    If you're starting a new venture, HorizonPredict provides critical insights into your spending and resource allocation, helping you make informed decisions and scale smartly.
                                                </Typography>
                                            </AccordionDetails>
                                        </StyledAccordion>
                                        <StyledAccordion 
                                            expanded={accordionExpanded === 'hobbyists'} 
                                            onChange={handleAccordionChange('hobbyists')}
                                            className="bg-transparent"
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMore />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                            >
                                                <Typography 
                                                    variant="h6"
                                                    className={`${accordionExpanded === 'hobbyists' ? 'text-red-500' : ''}`}
                                                >
                                                    Hobbyists
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    Even if you're managing projects as a hobby, keeping track of expenses can enhance your experience and efficiency. Our solution makes it easy and enjoyable.
                                                </Typography>
                                            </AccordionDetails>
                                        </StyledAccordion>
                                        <StyledAccordion 
                                            expanded={accordionExpanded === 'users'} 
                                            onChange={handleAccordionChange('users')}
                                            className="bg-transparent"
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMore />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                            >
                                                <Typography 
                                                    variant="h6"
                                                    className={`${accordionExpanded === 'users' ? 'text-red-500' : ''}`}
                                                >
                                                    Everyday Users
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    Interested in personal finance management? HorizonPredict offers simple, effective tools to monitor and manage personal expenses, helping you achieve your financial goals
                                                </Typography>
                                            </AccordionDetails>
                                        </StyledAccordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-[#FFF6F8]">
                <div ref={experienceRef} className={`py-16 mx-auto flex flex-col max-w-7xl px-6 lg:px-8 ${isVisibleExperience ? 'animate-fade animate-ease-in' : ''}`}>
                    <div className="text-3xl font-bold text-center">
                        Experience <el className='text-red-500'>HorizonPredict</el>
                    </div>
                    <div className="grid lg:grid-cols-3 gap-4 mt-12">
                        <div className="text-center bg-white p-12 rounded-lg">
                            <img
                                className="rounded-lg object-contain w-10 h-10"
                                src='home/fi_6850720.png'
                                alt=""
                                loading='lazy'
                            />
                            <div className="text-xl font-bold my-5">
                                Interactive Dashboard
                            </div>
                            <div>
                                Our user-friendly dashboard puts all your financial insights in one place. Access your inventory, product and ingredients, and visualize your costs with ease.
                            </div>
                        </div>
                        <div className="text-center bg-white p-12 rounded-lg">
                            <img
                                className="rounded-lg object-contain w-10 h-10"
                                src='home/fi_2059465.png'
                                alt=""
                                loading='lazy'
                            />
                            <div className="text-xl font-bold my-5">
                                Remove Unknown Factors
                            </div>
                            <div>
                                Unlock your entrepreneurial potential without the financial stress.
                            </div>
                        </div>
                        <div className="text-center bg-white p-12 rounded-lg">
                            <img
                                className="rounded-lg object-contain w-10 h-10"
                                src='home/fi_13089287.png'
                                alt=""
                                loading='lazy'
                            />
                            <div className="text-xl font-bold my-5">
                                Mobile Accessibility
                            </div>
                            <div>
                                Manage your finances on the go with our mobile-friendly platform. Whether at home or on the move, HorizonPredict keeps you connected and in control.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white">
                <div className="py-16 mx-auto flex flex-col max-w-7xl px-6 lg:px-8">
                    <div className="overflow-hidden">
                        <div className="relative mx-auto max-w-7xl py-8 px-5 lg:px-8">
                            <div className="mt-8 items-center lg:grid lg:grid-cols-2 lg:gap-8">
                                <div className={`relative lg:row-start-1 lg:col-start-1`}>
                                    <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
                                        <img
                                            className={`rounded-lg mt-2 object-contain w-full ${isVisibleUseCase ? 'animate-fade-right' : ''}`}
                                            src='home/usecase.png'
                                            alt=""
                                            loading='lazy'
                                            ref={useCaseRef}
                                        />
                                    </div>
                                </div>
                                <div className="mt-8 lg:mt-0">
                                    <div className='mx-auto max-w-prose lg:p-10'>
                                        <div className="text-3xl font-bold">
                                            Use Case <el className='text-red-500'>Examples</el>
                                        </div>
                                        <div className="mt-8">
                                            <div className="font-bold text-xl">For Small Businesses:</div>
                                            <div className="mt-2">Gain precise control over your expenses and inventory. HorizonPredict helps you understand every cost detail, ensuring that you can manage your business efficiently and sustainably.</div>
                                        </div>
                                        <div className="mt-8">
                                            <div className="font-bold text-xl">For Entrepreneurs:</div>
                                            <div className="mt-2">Get granular cost details and optimize your financial plans to launch your new venture with confidence. HorizonPredict is your partner in navigating the financial landscape of starting a business.</div>
                                        </div>
                                        <div className="mt-8">
                                            <div className="font-bold text-xl">For Hobbyists:</div>
                                            <div className="mt-2">Track the costs of your passion projects with ease. Whether it’s crafting, gardening, or any other hobby, HorizonPredict helps you manage your expenses and stay within budget.</div>
                                        </div>
                                        <div className="mt-8">
                                            <div className="font-bold text-xl">For Day-to-Day Users:</div>
                                            <div className="mt-2">Simplify your grocery and household budgeting. HorizonPredict makes it easy to track daily expenses and manage your finances efficiently.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-red-500">
                <div className="mx-auto flex flex-col max-w-7xl px-10">
                    <div className="relative mx-auto max-w-7xl">
                        <div className="flex flex-col lg:flex-row gap-x-8">
                            <div className="mt-8 lg:mt-0">
                                <div className='mx-auto max-w-prose lg:p-10 text-white'>
                                    <div className="text-3xl font-bold mt-5">
                                        Start Smart. Start Confident. Start with HorizonPredict.
                                    </div>
                                    <div className="mt-8">
                                        At HorizonPredict, we're excited to help you take charge of your finances with precision and ease. Join us as we redefine cost management and make financial optimization accessible to all. Explore our website to learn more about how our solutions can be tailored to your needs. Welcome to smarter financial management—welcome to HorizonPredict!
                                    </div>
                                </div>
                            </div>
                            <div className="relative mx-auto max-w-prose w-full lg:w-[900px] aspect-1 lg:-mt-32">
                                <img
                                    className={`rounded-lg object-cover absolute inset-0 w-full h-full`}
                                    src='home/feature-section.png'
                                    alt=""
                                    loading='lazy'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
