import { mainNavigation } from '../../../navigation/home/main-navigation';
import MenuItems from './MenuItems';

const Navbar = () => {
    return (
        <div className='flex'>
            <div className="menus">
                {mainNavigation.map((menu, index) => {
                    const depthLevel = 0;
                    return (
                        <MenuItems
                            items={menu}
                            key={index}
                            depthLevel={depthLevel}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default Navbar;
