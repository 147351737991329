export const mainNavigation = [
    {
        name: 'HOME', 
        href: '/'
    },
    {
        name: 'SOLUTIONS', 
        children: [
            {
                name: 'SMALL BUSINESSES',
                href: '/solutions/small-businesses'
            },
            {
                name: 'START UPS',
                href: '/solutions/start-ups'
            },
            {
                name: 'HOBBYISTS',
                href: '/solutions/hobbyists'
            },
            {
                name: 'INDIVIDUALS',
                href: '/solutions/individuals'
            }
        ]
    },
    {
        name: 'CONTACT', 
        href: '/contact'
    },
    {
        name: 'LOG IN', 
        href: '/login'
    }
];
