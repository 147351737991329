import { useState } from "react";
import { Link } from "react-router-dom";

import { 
    Close, 
    ExpandMore, 
    MenuOutlined 
} from "@mui/icons-material";

import { 
    Drawer, 
    IconButton 
} from "@mui/material";

import Navbar from "../../components/nav/top-nav/NavBar";
import { mainNavigation } from "../../navigation/home/main-navigation";
import SideNav from "../../components/nav/side-nav/SideNav";

export default function HeaderLayout() {
    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <header className="absolute z-50 top-0 inset-x-0">
            <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
                <div className="flex items-center gap-x-12">
                    <Link to="/" className="-m-1.5 p-1.5">
                        <img className="h-12 w-auto" src="/logo-transparent.png" alt="" />
                    </Link>
                </div>
                <div className="flex lg:hidden">
                    <IconButton
                        className="-m-2.5 inline-flex items-center justify-center p-2.5 text-white"
                        onClick={handleDrawerOpen}
                    >
                        <MenuOutlined/>
                    </IconButton>
                </div>
                <div className="hidden lg:flex gap-x-12 lg:items-center">
                    <Navbar />
                </div>
            </nav>
            <Drawer
                sx={{
                    width: '100%',
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: '100%',
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="right"
                open={open}
                className="lg:hidden"
            >
                <div className="flex flex-col gap-x-12 p-6 lg:px-8">
                    <div className="flex items-center justify-between">
                        <Link to="/" className="-m-1.5 p-1.5">
                            <img className="h-12 w-auto" src="/logo-transparent.png" alt="" />
                        </Link>
                        <IconButton
                            className="-m-2.5 inline-flex items-center justify-center p-2.5"
                            onClick={handleDrawerClose}
                        >
                            <Close />
                        </IconButton>
                    </div>
                    <div className="space-y-2 py-6 flex flex-col">
                        <SideNav navItems={mainNavigation }/>
                    </div>
                </div>
            </Drawer>
        </header>
    )
}
