import { 
    useState, 
    useEffect, 
    useRef 
} from 'react';
import { Link } from 'react-router-dom';

import { 
    ExpandMore, 
    KeyboardArrowRight 
} from '@mui/icons-material';

import Dropdown from './Dropdown';

const MenuItems = ({ items, depthLevel }) => {
    const [dropdown, setDropdown] = useState(false);
    const [pullLeft, setPullLeft] = useState(false);

    let ref = useRef();

    useEffect(() => {
        const handler = (event) => {
            if (dropdown && ref.current && !ref.current.contains(event.target)) {
                setDropdown(false);
            }
        };
        document.addEventListener('mousedown', handler);
        document.addEventListener('touchstart', handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', handler);
            document.removeEventListener('touchstart', handler);
        };
    }, [dropdown]);

    const onMouseEnter = () => {
        if (window.innerWidth < ref.current.getBoundingClientRect().right + 245) {
            setPullLeft(true);
        } else {
            setPullLeft(false);
        }
        setDropdown(true);
    };

    const onMouseLeave = () => {
        setDropdown(false);
    };

    const closeDropdown = () => {
        dropdown && setDropdown(false);
    };

    return (
        <div
            className="menu-items"
            ref={ref}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onMouseEnter}
        >
            {items.children ? (
                <>
                    <div
                        aria-haspopup="menu"
                        onClick={() => setDropdown((prev) => !prev)}
                        className='flex items-center py-3 px-4 rounded-lg hover:bg-red-500 gap-x-2'
                    >
                        <Link to={items.href} className='no-underline'>
                            {items.name}
                        </Link>
                        {depthLevel === 0 && (
                            <ExpandMore className='text-white'/>
                        )}
                        {depthLevel > 0 && (
                            <KeyboardArrowRight />
                        )}
                    </div>
                    <Dropdown
                        depthLevel={depthLevel}
                        submenus={items.children}
                        dropdown={dropdown}
                        pullLeft={pullLeft}
                    />
                </>
            ) : (
                <Link className='py-3 px-4 hover:bg-red-500 rounded-lg' to={items.href}>{items.name}</Link>
            )}
        </div>
    );
};

export default MenuItems;
