import { 
    useEffect,
    useState 
} from 'react';
import { useLocation } from 'react-router-dom';

import { 
    ListItemButton, 
    ListItemText 
} from '@mui/material';
import { 
    ExpandLess, 
    ExpandMore 
} from '@mui/icons-material';

export default function MenuItem(props) {
    const [open, setOpen] = useState(false);

    const location = useLocation();

    const { menu, onClick, depthLevel } = props;

    useEffect(() => {
        if (menu?.children?.some(e => e?.href === location.pathname)) {
            setOpen(true);
        }
    }, []);

    const isNavActive = (pathname, itemHref) => {
        const cleanPathname = pathname.replace(/\/$/, '');
        // Special case for home URL (root path)
        if (cleanPathname === '' && itemHref === '/') {
            return true;
        }
        return cleanPathname === itemHref;
    };

    const handleClick = () => {
        if (menu.href) {
            window.location.href = menu.href;
        } else {
            setOpen(!open);
            onClick(!open);
        }
    };

    return (
        <ListItemButton
            alignItems="flex-start"
            onClick={handleClick}
            className={`${isNavActive(location.pathname, menu.href) ? 'bg-red-500 text-white' : 'hover:bg-red-500'} hover:text-white group flex items-center px-2 py-3 font-medium rounded-md mb-1`}                
        >
            {menu.icon && (
                <menu.icon
                    className={`mr-3 flex-shrink-0 h-6 w-6 text-black}`}
                    aria-hidden="true"
                />
            )}
            <ListItemText
                primary={menu.name}
                primaryTypographyProps={{
                    fontSize: 15,
                    fontFamily: "'Public Sans', sans-serif",
                    fontWeight: 'medium',
                    lineHeight: '20px',
                    marginLeft: `${2.5 * (depthLevel - 1)}rem`
                }}
                sx={{ my: 0 }}
            />
            {menu?.children?.length > 0 && (
                (location.pathname.includes(menu.href) || open) ? <ExpandLess /> : <ExpandMore />
            )}
        </ListItemButton>
    )
}
