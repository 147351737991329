import { useState } from 'react';

import { 
    Button, 
    TextField, 
    Typography 
} from '@mui/material';

import { useJwtSignUpMutation } from '../../services/user';
import { useCreateCheckoutSessionMutation } from '../../services/stripe';

export default function SignUp() {
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(false);
    const [jwtSignUp, { isLoading }] = useJwtSignUpMutation();
    const [createCheckoutSession, { isCreatingSession }] = useCreateCheckoutSessionMutation();

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setError(e.target.value !== password);
    };

    const handleSignUp = async (e) => {
        e.preventDefault();
        let res = await jwtSignUp({
            body: {
                firstName: firstName,
                lastName: lastName,
                email: email,
                password,
            },
        }).unwrap();
        if (res.success) {
            res = await createCheckoutSession({}).unwrap();
            if (res.success) {
                window.location.href = res.session.url;
            }
        } else {
            // show notification
        }
    };

    return (
        <div className="min-h-full flex flex-col justify-center items-center h-screen sm:px-6 lg:px-8">
            <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">
                    <div className="flex flex-col gap-2 justify-center items-center w-full pb-8">
                        <img
                            src="logo-transparent.png"
                            alt="Logo"
                            className="h-16 w-auto"
                        />
                        <Typography className="text-3xl font-bold  p-1">
                            Create an Account
                        </Typography>
                    </div>
                    <form className="flex flex-col gap-4" onSubmit={handleSignUp}>
                        <div className="flex flex-row gap-4">
                            <TextField
                                type="text"
                                label="FirstName"
                                color="primary"
                                size="small"
                                fullWidth
                                id="firstName"
                                name="firstName"
                                placeholder=""
                                value={firstName}
                                required
                                onChange={(e) => setFirstName(e.target.value)}
                                variant="outlined"
                            />
                            <TextField
                                type="text"
                                label="LastName"
                                color="primary"
                                size="small"
                                fullWidth
                                id="lastName"
                                name="lastName"
                                placeholder=""
                                value={lastName}
                                required
                                onChange={(e) => setLastName(e.target.value)}
                                variant="outlined"
                            />
                            </div>
                            <TextField
                                type="text"
                                label="Email"
                                color="primary"
                                size="small"
                                fullWidth
                                id="email"
                                name="email"
                                placeholder=""
                                value={email}
                                required
                                onChange={(e) => setEmail(e.target.value)}
                                variant="outlined"
                            />
                            <TextField
                                type="password"
                                label="Password"
                                color="primary"
                                size="small"
                                fullWidth
                                id="Password"
                                name="Password"
                                variant="outlined"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <TextField
                                type="password"
                                label="Confirm Password"
                                color="primary"
                                size="small"
                                fullWidth
                                id="Confirm_Password"
                                name="Confirm_Password"
                                variant="outlined"
                                required
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                error={error}
                                helperText={error ? "Passwords do not match" : ""}
                            />
                            <div>
                                <Button
                                    className="bg-teal-350 hover:bg-teal-350 w-full"
                                    type="submit"
                                    variant="contained"
                                >
                                    <Typography className="text-sm font-medium text-white p-1">
                                    Sign Up
                                    </Typography>
                                </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
