import { horizonPredictApi } from "../../redux/apis/horizonPredict";

const stripeService = horizonPredictApi.injectEndpoints({
    endpoints: build => ({
        createCheckoutSession: build.mutation({
            query: ({ body }) => ({
                url: 'create-checkout-session',
                method: 'POST',
                body
            })
        }),
    })
});

export const {
    useCreateCheckoutSessionMutation,
} = stripeService;
