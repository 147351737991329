import { useState } from 'react';

import {
    Button, 
    FormControl, 
    Input, 
    InputAdornment, 
    InputLabel, 
    Select, 
    TextField, 
    styled
} from '@mui/material';

import { 
    Close, 
    Edit, 
    Save
} from '@mui/icons-material';

import * as _ from 'lodash';

import { 
    convertMeasurement,
    getMeasureName, 
    getMeasurement, 
    useGetMeasurementQuery 
} from '../../services/measurement';
import { 
    useDeleteInventoryByIdMutation, 
    useGetItemListQuery, 
    useUpdateInventoryMutation 
} from '../../services/inventory';
import AlertDlg from '../dialog/AlertDlg';

const StyledTextField = styled(TextField)({
    '& .MuiInputBase-input': {fontSize: 20, fontWeight: 'bold'},
    '& .MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor: "#000000",
        fontWeight: 'bold',
        fontSize: 20
    }
});

export default function InventoryCard({item}) {
    const {
        data: { measurement } = {},
        isLoading
    } = useGetMeasurementQuery();

    const {
        data: { itemList } = {},
        isLoading: isItemListLoading
    } = useGetItemListQuery();

    const [deleteInventory, { isLoading: isDeleting }] = useDeleteInventoryByIdMutation();
    const [updateInventory, { isLoading: isUpdating }] = useUpdateInventoryMutation();

    const [isEditingName, setIsEditingName] = useState(false);
    const [isAddValue, setIsAddValue] = useState(false);
    const [isRemoveValue, setIsRemoveValue] = useState(false);
    const [invName, setInvName] = useState(item.DisplayName !== '' ? item.DisplayName : item.InvName);

    const [openAlertDlg, setOpenAlertDlg] = useState(false);

    const [additionalCost, setAdditionalCost] = useState(0);
    const [additionalQuantity, setAdditionalQuantity] = useState(0);
    const [addMeasurement, setAddMeasurement] = useState(item.InvMeasure);

    const [removingQuantity, setRemovingQuantity] = useState(0);
    const [removingMeasurement, setRemovingMeasurement] = useState(item.InvMeasure);

    const handleClickEditName = async () => {
        setIsEditingName(prev => !prev) ;
        let result = await updateInventory({
            body: {
                inventory: {
                    InvID: item.InvID,
                    DisplayName: invName
                }
            }
        }).unwrap()
        if (result.success) {
            // todo: show notification
        }
    };

    const handleClickAdd = () => {
        setIsAddValue(prev => !prev);
    };

    const handleClickRemove = () => {
        setIsRemoveValue(prev => !prev);
    };

    const handleClickRemoveCard = () => {
        setOpenAlertDlg(true);
    };

    const handleConfirmRemoveCard = async () => {
        // add database operation
        let resp = await deleteInventory(item.InvID).unwrap();

        setOpenAlertDlg(false);
    };

    const handleCloseDlg = () => {
        setOpenAlertDlg(false);
    };

    const handleChangeName = (e) => {
        setInvName(e.target.value);
    };

    const calcQuantity = (quantity, sourceMeasurement) => {
        const targetUnit = _.find(_.flatten(_.values(measurement)), { "MeasureID": item.InvMeasure }).MeasureName;
        const sourceUnit = _.find(_.flatten(_.values(measurement)), { "MeasureID": parseInt(sourceMeasurement) }).MeasureName;
        const density = _.find(itemList, { "ItemName": item.InvName }).ItemDen;
        return convertMeasurement(quantity, sourceUnit, targetUnit, density);
    };

    const handleAddQuantity = async (e) => {
        let diffQuantity = calcQuantity(additionalQuantity, addMeasurement);
        const inventory = {
            InvID: item.InvID,
            InvCost: parseFloat(item.InvCost) + parseFloat(additionalCost),
            InvQuantity: parseFloat(item.InvQuantity) + diffQuantity,
            InvMeasure: item.InvMeasure
        };

        console.log('update inventory: ', inventory);

        let result = await updateInventory({
            body: { inventory }
        }).unwrap();
        if (result.success) {
            // todo: show notification
        }
    };

    const handleRemoveQuantity = async (e) => {
        let diffQuantity = calcQuantity(removingQuantity, removingMeasurement);
        const inventory = {
            InvID: item.InvID,
            InvCost: parseFloat(item.InvCost) - parseFloat(item.InvCost)/parseFloat(item.InvQuantity) * diffQuantity,
            InvQuantity: parseFloat(item.InvQuantity) - diffQuantity,
            InvMeasure: item.InvMeasure
        };
        let result = await updateInventory({
            body: { inventory }
        }).unwrap();
        if (result.success) {
            // todo: show notification
        }
    };

    return (
        <div className='m-2 p-5 shadow rounded-lg bg-white flex flex-col'>
            <div className='flex justify-end'>
                <div
                    className='p-1 cursor-pointer'
                    onClick={handleClickRemoveCard}
                >
                    <Close 
                        className='text-primary' 
                        fontSize='medium'
                        sx={{
                            fontWeight: 'bold',
                            strokeWidth: '2',
                            stroke: 'rgb(57 153 168 / var(--tw-text-opacity))'
                        }}
                    />
                </div>
            </div>
            <div className='text-center space-y-3 mt-3'>
                <StyledTextField 
                    className='text-center w-fit'
                    value={invName}
                    onChange={handleChangeName}
                    inputProps={{min: 0, style: { textAlign: 'center' }}} 
                    variant='standard'
                    disabled={!isEditingName}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment 
                                position="start" 
                                onClick={handleClickEditName}
                                className='cursor-pointer'
                            >
                                {isEditingName ? (
                                    <Save color='primary' />
                                ) : (
                                    <Edit color='primary' />
                                )}
                            </InputAdornment>
                        )
                    }}
                />
                <div className='text-xl'>
                    Cost: ${parseFloat(item.InvCost).toFixed(2)}
                </div>
                <div className='text-xl'>
                    Quantity: {item.InvQuantity} {getMeasureName(measurement, item.InvMeasure)}
                </div>
            </div>
            <div className='mt-5'>
                <div 
                    className='font-semibold text-xl text-center cursor-pointer'
                    onClick={handleClickAdd}
                >
                    + Add Quantity
                </div>
                {isAddValue && (
                    <div className='grid grid-cols-2 gap-3 mt-2'>
                        <TextField
                            type="text"
                            color="primary"
                            size="small"
                            fullWidth
                            id="additionalCost"
                            name="additionalCost"
                            placeholder=""
                            value={additionalCost}
                            label='Additional Cost'
                            onChange={e => setAdditionalCost(e.target.value)}
                            variant="outlined"
                        />
                        <TextField
                            type="text"
                            color="primary"
                            size="small"
                            fullWidth
                            id="additionalQuantity"
                            name="additionalQuantity"
                            placeholder=""
                            value={additionalQuantity}
                            label='Quantity'
                            onChange={e => setAdditionalQuantity(e.target.value)}
                            variant="outlined"
                        />
                        <FormControl fullWidth size='small'>
                            <InputLabel id="addMeasurement">Measure</InputLabel>
                            <Select
                                native 
                                labelId="addMeasurement"
                                color="primary"
                                id="addMeasurement"
                                name="addMeasurement"
                                label="Measure"
                                value={addMeasurement}
                                onChange={e => setAddMeasurement(e.target.value)}
                            >
                                <option aria-label="None" value="" />
                                {Object.keys(getMeasurement(measurement, item.InvMeasure)).map((item, index) => (
                                    <optgroup label={item}>
                                        {measurement[item].map((e, index) => (
                                            <option value={e.MeasureID}>{e.MeasureName}</option>
                                        ))}
                                    </optgroup>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            className='bg-primary hover:bg-primary'
                            variant="contained"
                            onClick={handleAddQuantity}
                        >
                            Add
                        </Button>
                    </div>
                )}
            </div>
            <div className='mt-3'>
                <div 
                    className='font-semibold text-xl text-center cursor-pointer'
                    onClick={handleClickRemove}
                >
                    - Remove Quantity
                </div>
                {isRemoveValue && (
                    <div className='grid grid-cols-2 gap-3 mt-2'>
                        <TextField
                            type="text"
                            color="primary"
                            size="small"
                            fullWidth
                            id="removingQuantity"
                            name="removingQuantity"
                            placeholder=""
                            value={removingQuantity}
                            label='Quantity'
                            onChange={e => setRemovingQuantity(e.target.value)}
                            variant="outlined"
                        />
                        <FormControl fullWidth size='small'>
                            <InputLabel id="removingMeasurement">Measure</InputLabel>
                            <Select
                                native 
                                labelId="removingMeasurement"
                                color="primary"
                                id="removingMeasurement"
                                name="removingMeasurement"
                                label="Measure"
                                value={removingMeasurement}
                                onChange={e => setRemovingMeasurement(e.target.value)}
                            >
                                <option aria-label="None" value="" />
                                {Object.keys(getMeasurement(measurement, item.InvMeasure)).map((item, index) => (
                                    <optgroup label={item}>
                                        {measurement[item].map((e, index) => (
                                            <option value={e.MeasureID}>{e.MeasureName}</option>
                                        ))}
                                    </optgroup>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            className='bg-primary hover:bg-primary'
                            variant="contained"
                            onClick={handleRemoveQuantity}
                        >
                            Remove
                        </Button>
                    </div>
                )}
            </div>
            <AlertDlg
                open={openAlertDlg}
                title="Removing inventory item?"
                content="Confirming this action will remove this item and other related data from the database permanently."
                onConfirm={handleConfirmRemoveCard}
                onClose={handleCloseDlg}
            />
        </div>
    )
}
