import { Outlet } from "react-router-dom";

import HeaderLayout from "./HeaderLayout";
import FooterLayout from "./FooterLayout";

export default function MainLayout() {
    return (
        <div className='main-layout'>
            <HeaderLayout />
            <div className='body-layout'>
                <Outlet />
            </div>
            <FooterLayout />
        </div>
    )
}
