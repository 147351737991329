import { useState } from "react";
import { 
    Link, 
    useNavigate 
} from "react-router-dom";

import {
    AppBar,
    Avatar,
    Box,
    Container,
    Drawer,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material'
import { 
    Close, 
    MenuOutlined
} from "@mui/icons-material";

import { userAccountMenu } from "../../navigation/dashboard/header-navigation";
import { sideBarNavigation } from "../../navigation/dashboard/side-navigation";

import SideNav from "../../components/nav/side-nav/SideNav";

export const HeaderLayout = () => {
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleOpenUserMenu = () => {
        setAnchorElUser(!anchorElUser);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleMenuItemClicked = (menu) => {
        navigate(menu.href);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <AppBar position="sticky" sx={{ bgcolor: "#fff", zIndex: "9999" }}>
            <Container maxWidth="2xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: { xs: "none", md: "flex" },
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                        }}
                    >
                        <img src="/logo-transparent.png" alt="Kitty Katty!" className="h-12 w-auto" />
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleDrawerOpen}
                            color="black"
                        >
                            <MenuOutlined />
                        </IconButton>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
                    <div className="flex items-center gap-6 -ml-10">
                        <Tooltip title="Open settings">
                            <IconButton
                                onClick={() => {
                                    handleOpenUserMenu();
                                }}
                                sx={{ p: 0 }}
                            >
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: "45px",ml:'10px' }}
                            id="menu-appbar"
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {userAccountMenu.map((menu) => (
                                <MenuItem key={menu.name} onClick={() => handleMenuItemClicked(menu)}>
                                    <ListItemIcon>
                                        <menu.icon fontSize='small' />
                                    </ListItemIcon>
                                    <Typography textAlign="center">{menu.name}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                </Toolbar>
            </Container>
            <Drawer
                sx={{
                    width: '100%',
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: '100%',
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
                className="lg:hidden"
            >
                <div className="flex flex-col gap-x-12 p-6 lg:px-8">
                    <div className="flex items-center justify-between">
                        <Link to="/" className="-m-1.5 p-1.5">
                            <img className="h-12 w-auto" src="/logo-transparent.png" alt="" />
                        </Link>
                        <IconButton
                            className="-m-2.5 inline-flex items-center justify-center p-2.5"
                            onClick={handleDrawerClose}
                        >
                            <Close />
                        </IconButton>
                    </div>
                    <div className="space-y-2 py-6 flex flex-col">
                        <SideNav navItems={sideBarNavigation } />
                    </div>
                </div>
            </Drawer>
        </AppBar>
    );
};
