import { 
    Button, 
    TextField 
} from '@mui/material';

export default function Contact() {
    return (
        <div className='flex flex-col'>
            <div className="relative isolate">
                <img
                    src="/contact/header.png"
                    alt=""
                    className="absolute -z-10 object-cover h-full w-full inset-0 transition-all duration-500"
                />
                <div className="py-32 mx-auto flex flex-col max-w-7xl px-6 lg:px-8">
                    <div className='text-white'>
                        Welcome to HorizonPredict
                    </div>
                    <div className="my-5 space-y-3">
                        <div className="text-3xl font-bold text-white max-w-sm leading-normal">
                            Your ultimate partner in transformative cost <el className='text-red-500'>management solutions</el>.
                        </div>
                    </div>
                    <div className='text-white max-w-lg'>
                        Here at HorizonPredict, we are dedicated to empowering businesses, entrepreneurs, hobbyists, and individuals with advanced tools to understand and optimize their financial operations down to the finest details.
                    </div>
                    <div className="px-5 py-2 bg-red-500 w-fit mt-12 rounded-xl text-white">
                        <a href="/sign-up" className="no-underline text-white">SIGN UP</a>
                    </div>
                </div>
            </div>
            <div className='relative bg-white'>
                <div className='mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2'>
                    <div className='relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48'>
                        <h2>Get in Touch <span className='text-red-500'>With Us</span></h2>
                        <div className='my-8'>
                            Lorem ipsum dolor sit amet consectetur. Tortor tincidunt lorem ac libero arcu quis. Velit vel neque ultrices mauris ultricies erat risus adipiscing. Viverra venenatis congue tellus dolor consectetur. In eget amet aenean ipsum quam tortor tortor. 
                        </div>
                        <div className='space-y-4'>
                            <div className='flex gap-x-4 items-center'>
                                <img
                                    src="/contact/email.png"
                                    alt=""
                                    className="size-5"
                                />
                                <div className='text-xl'>youremail@email.com</div>
                            </div>
                            <div className='flex gap-x-4 items-center'>
                                <img
                                    src="/contact/phone.png"
                                    alt=""
                                    className="size-5"
                                />
                                <div className='text-xl'>00 11 22 33 4444</div>
                            </div>
                            <div className='flex gap-x-4 items-center'>
                                <img
                                    src="/contact/linkedin.png"
                                    alt=""
                                    className="size-5"
                                />
                                <div className='text-xl'>loremip.sumdolor</div>
                            </div>
                            <div className='flex gap-x-4 items-center'>
                                <img
                                    src="/contact/instagram.png"
                                    alt=""
                                    className="size-5"
                                />
                                <div className='text-xl'>@loremipsumdolor</div>
                            </div>
                        </div>
                    </div>
                    <div className='mx-auto max-w-xl px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48 space-y-6'>
                        <div className='grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2'>
                            <TextField
                                type="text"
                                label="First Name"
                                color="primary"
                                size="small"
                                fullWidth
                                id="firstName"
                                name="firstName"
                                placeholder=""
                                // value={firstName}
                                // onChange={e => setFirstName(e.target.value)}
                                variant="outlined"
                            />
                            <TextField
                                type="text"
                                label="Last Name"
                                color="primary"
                                size="small"
                                fullWidth
                                id="lastName"
                                name="lastName"
                                placeholder=""
                                // value={firstName}
                                // onChange={e => setFirstName(e.target.value)}
                                variant="outlined"
                            />
                        </div>
                        <TextField
                            type="text"
                            label="Email"
                            color="primary"
                            size="small"
                            fullWidth
                            id="email"
                            name="email"
                            placeholder=""
                            // value={firstName}
                            // onChange={e => setFirstName(e.target.value)}
                            variant="outlined"
                        />
                        <TextField
                            type="text"
                            label="Phone"
                            color="primary"
                            size="small"
                            fullWidth
                            id="phone"
                            name="phone"
                            placeholder=""
                            // value={firstName}
                            // onChange={e => setFirstName(e.target.value)}
                            variant="outlined"
                        />
                        <TextField
                            label="Message"
                            color="primary"
                            fullWidth
                            multiline
                            rows={5}
                            id="message"
                            name="message"
                            placeholder=""
                            // value={firstName}
                            // onChange={e => setFirstName(e.target.value)}
                            variant="outlined"
                        />
                        <Button
                            className='bg-red-500 w-fit float-right'
                            type="submit"
                            variant="contained"
                        >
                            Send Message
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
