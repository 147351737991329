import { List } from '@mui/material';

import { sideBarNavigation } from '../../navigation/dashboard/side-navigation';
import { NavLink } from 'react-router-dom';

export const Sidebar = (props) => {
    return (
        <List className='side-bar w-[180px] h-full bg-gray-800 space-y-4'>
            {sideBarNavigation.map((item, index) => (
                <div 
                    key={item.name}
                    disablePadding
                    className='flex text-white cursor-pointer my-3 mx-3'
                >
                    <NavLink
                        to={item.href}
                        className='w-full text-white flex items-center hover:bg-primary rounded-md no-underline'
                    >
                        <div className='text-white flex items-center hover:bg-primary rounded-md'>
                            <item.icon 
                                className='m-2 w-8 h-8'
                            />
                            <div className='text-lg font-bold'>
                                {item.name}
                            </div>
                        </div>
                    </NavLink>
                </div>
            ))}
        </List>
    );
}
  