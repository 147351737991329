import { Outlet } from "react-router-dom";

import BodyLayout from "./BodyLayout";
import { HeaderLayout } from "./HeaderLayout";

export default function Layout() {
    return (
        <div className="dashboard-layout h-full flex flex-col">
            <HeaderLayout />
            <BodyLayout>
                <Outlet />
            </BodyLayout>
        </div>
    )
}
