import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import * as _ from 'lodash';

import { useGetInventoryQuery } from '../../services/inventory'
import { useGetProductsQuery } from '../../services/products';
import { 
    getMeasureName, 
    useGetMeasurementQuery 
} from '../../services/measurement';

export default function Dashboard() {
    const {
        data : { inventory } = {},
        error: inventoryError,
        isLoading: isInventoryLoading
    } = useGetInventoryQuery(); // just for demo userId = 1

    const {
        data : { products } = {},
        error:  productsError,
        isLoading: isProductsLoading
    } = useGetProductsQuery(); // just for demo userId = 1

    const {
        data: { measurement } = {},
        isLoading
    } = useGetMeasurementQuery();

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };

    return (
        <div className='dashboard-wrapper'>
            {inventory && products && (
                <div className='space-y-8'>
                    <div className='text-3xl font-bold'>
                        Hello {JSON.parse(localStorage.getItem('user'))?.userName}
                    </div>
                    <div className='p-5 shadow rounded-lg bg-gray-50 inventory-wrapper'> 
                        <div className='text-3xl font-semibold'>Inventory</div>
                        <div className='mt-5 cursor-pointer'>
                            <Carousel 
                                responsive={responsive}
                                partialVisbile
                                swipeable={true}
                                arrows={true}
                            >
                                {inventory.map((item, index) => (
                                    <div className='text-center m-3 p-3 shadow rounded-lg bg-white space-y-3' key={index}>
                                        <div className='text-2xl font-semibold'>
                                            {!item.DisplayName ? item.InvName : item.DisplayName}
                                        </div>
                                        <div className='text-xl'>
                                            Cost: ${parseFloat(item.InvCost).toFixed(2)}
                                        </div>
                                        <div className='text-xl'>
                                            Remaining Quantity: {item.InvQuantity} {getMeasureName(measurement, item.InvMeasure)}
                                        </div>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    </div>
                    <div className='mt-5 p-5 shadow rounded-lg products-wrapper bg-gray-50'> 
                        <div className='text-3xl font-semibold'>Products</div>
                        <div className='mt-5 cursor-pointer'>
                            <Carousel 
                                responsive={responsive}
                                partialVisbile
                                swipeable={true}
                                arrows={true}
                            >
                                {products.map((item, index) => (
                                    <div className='text-center m-3 p-3 shadow rounded-lg bg-white space-y-3' key={index}>
                                        <div className='text-2xl font-semibold'>
                                            {item.ProdName}
                                        </div>
                                        <div className='mt-8 space-y-3'>
                                            {item?.ingredients?.map((ing, index) => {
                                                return (
                                                    <div className='grid grid-cols-3 text-lg text-center'>
                                                        <div className=''>
                                                            {ing.IngName}
                                                        </div>
                                                        <div className=''>
                                                            {ing.IngQuantity}
                                                        </div>
                                                        <div className=''>
                                                            {_.find(_.flatten(_.values(measurement)), { "MeasureID": ing.MeasureID }).MeasureName}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
