import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { 
    Button, 
    TextField, 
    Typography 
} from '@mui/material';
import { useJwtLoginMutation } from '../../services/user';

export default function Login() {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [jwtLogin, {isLoading}] = useJwtLoginMutation();

    const navigate = useNavigate();
    
    const handleLogin = async (e) => {
        // api call to handle login
        e.preventDefault();
        let resp = await jwtLogin({
            body: {
                username: email,
                password
            }
        }).unwrap();
        if (resp.success) {
            // todo: move to the redux persist or JWT
            localStorage.setItem('user', JSON.stringify(resp.user));
            navigate('/dashboard');
        } else {
            // show error toast
        }
    };

    return (
        <div className="min-h-full flex flex-col justify-center items-center h-screen sm:px-6 lg:px-8">
            <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">
                    <div className="flex flex-row justify-center items-center w-full pb-10 ">
                        <img src="logo-transparent.png" alt="Logo" className='h-16 w-auto' />
                    </div>
                    <form className="space-y-6" onSubmit={handleLogin}>
                        <div className="mt-1">
                            <TextField
                                type="text"
                                label="Email"
                                color="primary"
                                size="small"
                                fullWidth
                                id="email"
                                name="email"
                                placeholder=""
                                value={email}
                                required
                                onChange={e => setEmail(e.target.value)}
                                variant="outlined"
                            />
                        </div>
                        <div className="mt-1">
                            <TextField
                                type="password"
                                label="Password"
                                color="primary"
                                size="small"
                                fullWidth
                                id="Password"
                                name="Password"
                                variant="outlined"
                                required
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="flex items-center justify-end">
                            <div className="text-sm">
                                <a href="/forgot-password" rel="noreferrer" target="_blank" className="fort-medium text-primary m-0 underline">
                                    Forgot your password?
                                </a>
                            </div>
                        </div>
                        <div>
                            <Button
                                className='bg-primary hover:bg-primary w-full'
                                type="submit"
                                variant="contained"
                            >
                                <Typography className="text-sm font-medium text-white p-1">Sign in</Typography>
                            </Button>
                        </div>
                        <div className='text-center'>No account? <a className='text-primary' href='/sign-up'>Create one now</a></div>
                    </form>
                </div>
            </div>
        </div>
    )
}
