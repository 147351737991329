import { horizonPredictApi } from "../../redux/apis/horizonPredict";

const userService = horizonPredictApi.injectEndpoints({
    endpoints: build => ({
        jwtLogin: build.mutation({
            query: ({params, body}) => ({
                url: 'jwt.login',
                method: 'POST',
                body
            })
        }),
        jwtSignUp: build.mutation({
            query: ({ params, body }) => ({
                url: 'jwt.signup',
                method: "POST",
                body,
            }),
        }),
    })
});

export const {
    useJwtLoginMutation,
    useJwtSignUpMutation
} = userService;
