import { 
    HomeOutlined, 
    InventoryOutlined, 
    ProductionQuantityLimits
} from '@mui/icons-material';

export const sideBarNavigation = [
    {
        name: 'Dashboard',
        href: '/dashboard',
        icon: HomeOutlined
    },
    {
        name: 'Inventory',
        href: '/inventory',
        icon: InventoryOutlined
    },
    {
        name: 'Products',
        href: '/products',
        icon: ProductionQuantityLimits
    },
];
