import {
    createApi,
    fetchBaseQuery
} from '@reduxjs/toolkit/query/react';

export const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: headers => getAuthHeaders(headers)
});

const getAuthHeaders = headers => {
    headers = headers || new Headers();

    headers.set('X-App-Authorization', process.env.REACT_APP_APP_TOKEN);

    return headers;
};

export const horizonPredictApi = createApi({
    reducerPath: 'horizonPredictApi',
    baseQuery: baseQuery,
    endpoints: () => ({}),
    tagTypes: [
        'Inventory'
    ]
});
