import { 
    Button, 
    TextField 
} from '@mui/material';

export default function SmallBusinesses() {
    return (
        <div className='flex flex-col'>
            <div className="relative isolate">
                <img
                    src="/solutions/small-business-header.png"
                    alt=""
                    className="absolute -z-10 object-cover h-full w-full inset-0 transition-all duration-500"
                />
                <div className="py-32 mx-auto flex flex-col max-w-7xl px-6 lg:px-8">
                    <div className='text-white'>
                        Welcome to HorizonPredict
                    </div>
                    <div className="my-5 space-y-3">
                        <div className="text-3xl font-bold text-white max-w-sm leading-normal">
                            Your ultimate partner in transformative cost <el className='text-red-500'>management solutions</el>.
                        </div>
                    </div>
                    <div className='text-white max-w-lg'>
                        Here at HorizonPredict, we are dedicated to empowering businesses, entrepreneurs, hobbyists, and individuals with advanced tools to understand and optimize their financial operations down to the finest details.
                    </div>
                    <div className="px-5 py-2 bg-red-500 w-fit mt-12 rounded-xl text-white">
                        <a href="/sign-up" className="no-underline text-white">SIGN UP</a>
                    </div>
                </div>
            </div>
            <div className="bg-white">
                <div className="py-16 mx-auto flex flex-col max-w-7xl px-6 lg:px-8">
                    <div className="overflow-hidden">
                        <div className="relative mx-auto max-w-7xl py-8 px-5 lg:px-8">
                            <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
                                <div className={`relative lg:row-start-1 lg:col-start-1`}>
                                    <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
                                        <img
                                            className="rounded-lg object-contain w-full"
                                            src='/solutions/small-bus.png'
                                            alt=""
                                            loading='lazy'
                                        />
                                    </div>
                                </div>
                                <div className="mt-8 lg:mt-0">
                                    <div className='mx-auto max-w-prose lg:p-10'>
                                        <div className="text-3xl font-bold mt-5">
                                            A Game-Changer for <br /><el className='text-red-500'>Small Businesses</el>
                                        </div>
                                        <div className="mt-8">
                                            Gain precise control over your expenses and inventory with HorizonPredict, the ultimate tool for small businesses. Managing a small business often involves juggling numerous tasks, and keeping track of inventory and expenses can be particularly challenging. Without a clear understanding of cost details, it's easy to overspend or understock, leading to financial strain and missed opportunities. HorizonPredict steps in to alleviate these pain points by providing granular insights into every cost associated with your products. With this tool, you can meticulously track your inventory, monitor expenses in real-time, and make informed decisions that promote efficiency and sustainability. HorizonPredict’s user-friendly interface ensures that even those without a financial background can navigate and utilize its features effectively. By helping you identify cost-saving opportunities and optimize inventory levels, HorizonPredict empowers you to achieve better financial health and operational control, paving the way for sustained growth and success.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
