import React from 'react';

import { 
    List 
} from '@mui/material';

import MenuGroup from './MenuGroup';

export default function SideNav(props) {
    const { navItems } = props;
    
    return (
        <List sx={{width: '100%'}} disablePadding>
            {navItems.map((item) => {
                const depthLevel = 0;
                return (
                    <MenuGroup 
                        key={item.name} 
                        menu={item} 
                        depthLevel={depthLevel} 
                    />
                )
            })}
        </List>
    )
}
