import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
 } from '@mui/material';
import { Close } from '@mui/icons-material';

export default function AlertDlg(props) {
    const { 
        open,
        title,
        content,
        onClose,
        onConfirm,
    } = props;


    const handleClose = () => {
        onClose();
    };

    const handleConfirm = () => {
        onConfirm();
    };
    
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                <Close />
            </IconButton>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm} variant='contained' autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}
