import { Sidebar } from './Sidebar'

export default function BodyLayout(props) {
    return (
        <div className='dashboard-body-layout w-full flex'>
            <div className='side-bar--wrapper hidden md:block'>
                <Sidebar />
            </div>
            <div className='main-content w-full h-full overflow-y-auto p-5 lg:p-16'>
                {props.children}
            </div>
        </div>
    )
}
