import { horizonPredictApi } from "../../redux/apis/horizonPredict";

const productsService = horizonPredictApi.injectEndpoints({
    endpoints: build => ({
        getProducts: build.query({
            query: (userId) => ({
                url: `products.fetch`,
                method: 'GET',
            }),
            providesTags: ['Products']
        }),
        getProdCategory: build.query({
            providesTags: ['ProdCategory'],
            query: () => ({
                url: `prodcategory.fetch`,
                method: 'GET',
            }),
        }),
        deleteProductById: build.mutation({
            query: (productId) => ({
                url: `products.remove/${productId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Products']
        }),
        deleteIngById: build.mutation({
            query: (ingId) => ({
                url: `ingredients.remove/${ingId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Products']
        }),
        upsertProduct: build.mutation({
            query: ({ body }) => ({
                url: `products.upsert`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['Products', 'ProdCategory']
        })
    })
});

export const {
    useGetProductsQuery,
    useGetProdCategoryQuery,
    useDeleteProductByIdMutation,
    useDeleteIngByIdMutation,
    useUpsertProductMutation
} = productsService;
