import { useState } from 'react';

import { 
    Autocomplete,
    Button, 
    FormControl, 
    InputLabel, 
    ListSubheader, 
    MenuItem, 
    Select, 
    TextField, 
    Tooltip, 
    styled 
} from '@mui/material';
import { 
    Close, 
    Edit
} from '@mui/icons-material';

import * as _ from 'lodash';

import { 
    useBatchInventoryMutation,
    useGetInventoryQuery, 
    useGetItemListQuery 
} from '../../services/inventory';
import { 
    convertMeasurement,
    getDensity,
    getMeasureName, 
    getMeasurement, 
    getUnit, 
    useGetMeasurementQuery 
} from '../../services/measurement';
import { 
    useDeleteIngByIdMutation,
    useDeleteProductByIdMutation,
    useUpsertProductMutation
} from '../../services/products';
import AlertDlg from '../dialog/AlertDlg';

const StyledTextField = styled(TextField)({
    '& .MuiInputBase-input': {fontSize: 20, fontWeight: 'bold'},
    '& .MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor: "#000000",
        fontWeight: 'bold',
        fontSize: 20
    }
});

export default function ProductCard({item}) {
    const [isEditing, setIsEditing] = useState(false);
    const [prodName, setProdName] = useState(item.ProdName);
    const [openAlertDlg, setOpenAlertDlg] = useState(false);
    const [tempIngredients, setTempIngredients] = useState(item.ingredients);

    const {
        data: { measurement } = {},
        isLoading
    } = useGetMeasurementQuery();
    const {
        data: { itemList } = {},
        isLoading: isItemListLoading
    } = useGetItemListQuery();

    const [deleteProduct, { isLoading: isDeleting }] = useDeleteProductByIdMutation();
    const [deleteIng, { isLoading: isIngDeleting }] = useDeleteIngByIdMutation();
    const [upsertProduct, { isLoading: isUpserting }] = useUpsertProductMutation();
    const [batchInventory, { isLoading: isBatching }] = useBatchInventoryMutation();

    const {
        data: { inventory } = {},
        error: inventoryError,
        isLoading: isInventoryLoading
    } = useGetInventoryQuery(); // just for demo userId = 1

    const handleClickEdit = async (e) => {
        e.preventDefault();
        if (isEditing) { // need to save the data
            let nIngredients = [];
            for (let i = 0; i < tempIngredients.length; i++) {
                const invId = inventory.find(item => item.DisplayName === e.target[`ingName${i}`].value || item.InvName === e.target[`ingName${i}`].value).InvID;
                let temp = {
                    "IngID": tempIngredients[i].IngID,
                    "IngName": e.target[`ingName${i}`].value,
                    "IngQuantity": e.target[`ingQuantity${i}`].value,
                    "MeasureID": parseInt(e.target[`ingMeasure${i}`].value),
                    "ProdID": item.ProdID,
                    "InvID": invId
                }
                console.log('form value: ', temp);
                nIngredients.push(temp);
            }

            let {ingredients, ...product} = item;
            product = {
                ...product,
                ProdName: e.target.prodName.value
            }

            let result = await upsertProduct({
                body: {
                    product,
                    ingredients: nIngredients
                }
            }).unwrap();
            if (result.success) {
                console.log('update success');
                setTempIngredients(result.product.ingredients);
                // todo: show notification
            }
        } 
        setIsEditing(prev => !prev);
    };

    // calculate ingredient cost
    const calCost = (ingredient) => {
        if (!ingredient.InvID || !ingredient.MeasureID || !ingredient.IngQuantity) return 0;
        if (inventory && itemList) {
            const targetUnit = getUnit(measurement, ingredient.MeasureID);

            const invItem = inventory.find(e => e.InvID === ingredient.InvID);
            const sourceUnit = getUnit(measurement, invItem.InvMeasure);

            const density = getDensity(itemList, invItem.InvName);

            const invQuantity = convertMeasurement(parseFloat(invItem.InvQuantity), sourceUnit, targetUnit, density);
            const cost = parseFloat(invItem.InvCost) / invQuantity * parseFloat(ingredient.IngQuantity);
            return cost;
        }
    };

    // calculate total cost
    const calTotalCost = () => {
        return tempIngredients.reduce(
            (acc, cur) => calCost(cur) + acc,
            parseFloat(item.ProdTimeCost ?? 0)
        ).toFixed(2);
    };

    const handleClickRemoveCard = () => {
        setOpenAlertDlg(true);
    };

    const handleConfirmRemoveCard = async () => {
        // todo: to be inside try catch statement
        let resp = await deleteProduct(item.ProdID).unwrap();
        
        setOpenAlertDlg(false);
    };

    const handleCloseDlg = () => {
        setOpenAlertDlg(false);
    };

    const handleCreateBatch = () => {
        let invArry = [];
        for (let i = 0; i < tempIngredients.length; i++) {
            const ing = tempIngredients[i];
            const InvID = ing.InvID;
            const sourceUnit = getUnit(measurement, ing.MeasureID);
            
            const invItem = inventory.find(e => e.InvID === InvID);
            const targetUnit = getUnit(measurement, invItem.InvMeasure);
            const density = getDensity(itemList, invItem.InvName);
            const ingQuantity = convertMeasurement(parseFloat(ing.IngQuantity), sourceUnit, targetUnit, density);
            const InvQuantity = parseFloat(invItem.InvQuantity) - ingQuantity;
            const InvCost = parseFloat(invItem.InvCost) - calCost(ing);

            const newInv = {
                InvID,
                InvQuantity,
                InvCost
            };
            invArry.push(newInv);
        }
        console.log('batch: ', invArry);
        batchInventory({
            body: {
                inventory: invArry
            }
        });
    };

    const handleChangeProdName = (e) => {
        setProdName(e.target.value);
    };

    const handleAddIngredient = () => {
        const ingredient = {
            IngName: null,
            InvID: null,
            IngQuantity: null,
            MeasureID: '',
            ProdID: item.ProdID
        };
        setTempIngredients(prev => [
            ...prev,
            ingredient
        ]);
    };

    const handleIngNameChange = (e, index, newVal) => {
        console.log('change event: ', e.target.name, index, newVal);
        setTempIngredients(prev => [
            ...prev.slice(0, index),
            {
                ...prev[index],
                IngName: newVal.DisplayName !== '' ? newVal.DisplayName : newVal.InvName,
                InvID: newVal.InvID
            },
            ...prev.slice(index + 1)
        ]);
    };

    const handleIngChange = (e, index) => {
        console.log('change event: ', e.target.name, e.target.value);
        if (e.target.name.includes('ingQuantity')) {
            setTempIngredients(prev => [
                ...prev.slice(0, index),
                {
                    ...prev[index],
                    IngQuantity: e.target.value
                },
                ...prev.slice(index + 1)
            ]);
        }
        if (e.target.name.includes('ingMeasure')) {
            console.log('measure change: ', e.target.value)
            setTempIngredients(prev => [
                ...prev.slice(0, index),
                {
                    ...prev[index],
                    MeasureID: parseInt(e.target.value)
                },
                ...prev.slice(index + 1)
            ]);
        }
    };

    const handleClickRemoveIng = (index, ingredient) => {
        setTempIngredients(prev => [
            ...prev.slice(0, index),
            ...prev.slice(index + 1)
        ]);
        if (ingredient.IngID) {
            let result = deleteIng(ingredient.IngID).unwrap();
            if (result.success) {
                // todo: show notification
            }
        }
    };

    if (isUpserting) {
        return <>Loading</>
    }

    return (
        <form  onSubmit={handleClickEdit} className='m-2 p-5 shadow rounded-lg bg-white flex flex-col'>
            <div className='flex justify-between'>
                <Button
                    variant='outlined'
                    startIcon={<Edit />}
                    className='w-fit' 
                    size='small'
                    type='submit'
                >
                    {isEditing ? 'Save' : 'Edit Product'}
                </Button>
                <div
                    className='p-1 cursor-pointer'
                    onClick={handleClickRemoveCard}
                >
                    <Close 
                        className='text-primary' 
                        fontSize='medium'
                        sx={{
                            fontWeight: 'bold',
                            strokeWidth: '2',
                            stroke: 'rgb(57 153 168 / var(--tw-text-opacity))'
                        }}
                    />
                </div>
            </div>
            <div className='text-center space-y-3 mt-3'>
                <StyledTextField 
                    className='w-fit'
                    value={prodName}
                    onChange={handleChangeProdName}
                    inputProps={{min: 0, style: { textAlign: 'center' }}} 
                    variant='standard'
                    id='prodName'
                    name='prodName'
                    disabled={!isEditing}
                />
            </div>
            <div className='mt-8 space-y-8'>
                {tempIngredients?.map((ing, index) => {
                    if (isEditing) {
                        return (
                            // <div className='flex space-y-3 2xl:space-y-0 2xl:space-x-2 flex-col 2xl:flex-row shadow rounded-lg p-3'>
                            <div className='flex space-y-3 flex-col shadow rounded-lg p-3' key={ing.IngID} onClick={() => console.log('ing: ', ing)}>
                                <Autocomplete
                                    disablePortal
                                    id={`ingName${index}`}
                                    name={`ingName${index}`}
                                    size='small'
                                    fullWidth
                                    options={inventory}
                                    defaultValue={inventory.find(e => e.InvName === ing.IngName || e.DisplayName === ing.IngName)}
                                    getOptionLabel={(option) => option.DisplayName ? option.DisplayName : option.InvName }
                                    renderInput={(params) => <TextField {...params} label="Ingredient" required />}
                                    onChange={(e, newVal) => handleIngNameChange(e, index, newVal)}
                                />
                                <TextField
                                    type="text"
                                    color="primary"
                                    size="small"
                                    fullWidth
                                    id={`ingQuantity${index}`}
                                    name={`ingQuantity${index}`}
                                    label='Quantity'
                                    // onChange={e => setEmail(e.target.value)}
                                    variant="outlined"
                                    disabled={!isEditing}
                                    defaultValue={ing.IngQuantity}
                                    onChange={(e) => handleIngChange(e, index)}
                                />
                                <FormControl fullWidth size='small'>
                                    <InputLabel id="measurement">Measure</InputLabel>
                                    <Select
                                        native 
                                        labelId="measurement"
                                        color="primary"
                                        id={`ingMeasure${index}`}
                                        name={`ingMeasure${index}`}
                                        label="Measure"
                                        value={ing.MeasureID}
                                        onChange={(e) => handleIngChange(e, index)}
                                    >
                                        <option aria-label="None" value="" />
                                        {Object.keys(getMeasurement(measurement, ing.MeasureID)).map((item) => (
                                            <optgroup label={item}>
                                                {measurement[item].map((e) => (
                                                    <option value={e.MeasureID}>{e.MeasureName}</option>
                                                ))}
                                            </optgroup>
                                        ))}
                                    </Select>
                                </FormControl>
                                <div className='flex justify-between items-center'>
                                    <div className=''>
                                        ${calCost(ing)?.toFixed(2)}
                                    </div>
                                    <div
                                        className='float-right p-1 cursor-pointer'
                                        onClick={() => handleClickRemoveIng(index, ing)}
                                    >
                                        <Close 
                                            className='text-primary' 
                                            fontSize='medium'
                                            sx={{
                                                fontWeight: 'bold',
                                                strokeWidth: '2',
                                                stroke: 'rgb(57 153 168 / var(--tw-text-opacity))'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <div className='shadow rounded-lg p-3 grid grid-cols-4 text-lg text-center items-center'>
                                <div className=''>
                                    {ing.IngName}
                                </div>
                                <div className=''>
                                    {ing.IngQuantity}
                                </div>
                                <div className=''>
                                    {getMeasureName(measurement, ing.MeasureID)}
                                </div>
                                <div className=''>
                                    ${calCost(ing)?.toFixed(2)}
                                </div>
                            </div>
                        )
                    }
                })}
                {isEditing && (
                    <Button
                        className='w-full'
                        variant="contained"
                        onClick={handleAddIngredient}
                    >
                        Add Ingredient
                    </Button>
                )}
            </div>
            {item.ProdTimeCost && (
                <div className='flex justify-between p-3 mx-2 text-lg mt-2'>
                    <div className=''>Labor Cost</div>
                    <div className=''>${parseFloat(item.ProdTimeCost).toFixed(2)}</div>
                </div>
            )}
            <div className='text-center'>
                <div className='text-xl font-medium mt-5'>
                    ${calTotalCost()}
                </div>
                {item.SubQuan && (
                    <div className='text-xl mt-2'>
                        ${(calTotalCost() / item.SubQuan).toFixed(2)} / per serving
                    </div>
                )}
                {item.SubQuan && (
                    <div className='text-xl mt-2'>
                        {item.SubQuan} servings
                    </div>
                )}
            </div>
            <div className='mt-8 w-full flex'>
                <Tooltip title="This will remove the quantity from the Inventory Page">
                    <Button
                        className='w-full hover:bg-primary'
                        variant="contained"
                        onClick={handleCreateBatch}
                    >
                        Create Batch
                    </Button>
                </Tooltip>
            </div>
            <AlertDlg
                open={openAlertDlg}
                title="Removing product item?"
                content="Confirming this action will remove this item and other related data from the database permanently."
                onConfirm={handleConfirmRemoveCard}
                onClose={handleCloseDlg}
            />
        </form>
    )
}
