import { useEffect, useState } from 'react';

import { 
    Autocomplete,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel, 
    IconButton, 
    InputLabel, 
    Select,
    TextField,
    Tooltip,
} from '@mui/material';

import { 
    CheckBox,
    CheckBoxOutlineBlank,
    Close, 
    HelpOutlineOutlined,
} from '@mui/icons-material';

import * as _ from 'lodash';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import ProductCard from '../../components/products/ProductCard';
import { useGetInventoryQuery } from '../../services/inventory';
import { useGetMeasurementQuery } from '../../services/measurement';
import { 
    useGetProdCategoryQuery,
    useGetProductsQuery, 
    useUpsertProductMutation 
} from '../../services/products';
import { capitalizeFirstLetter } from '../../utils/string-helpers';

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
};

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

export default function Products() {
    const {
        data : { products } = {},
        error,
        isLoading: isProductsLoading
    } = useGetProductsQuery();
    const {
        data: { measurement } = {},
        isLoading
    } = useGetMeasurementQuery();
    const {
        data: { inventory } = {},
        error: inventoryError,
        isLoading: isInventoryLoading
    } = useGetInventoryQuery(); // just for demo userId = 1

    const {
        data: { category } = {},
        error: prodCategoryError,
        isLoading: isProdCatLoading
    } = useGetProdCategoryQuery(); // just for demo userId = 1

    const [upsertProduct, { isLoading: isUpserting }] = useUpsertProductMutation();

    const [categoryRequired, setCategoryRequired] = useState(false);
    const [laborTimeRequired, setLaborTimeRequired] = useState(false);
    const [servingAmountRequired, setServingAmountRequired] = useState(false);

    const [ingredients, setIngredients] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState();
    const [header, setHeader] = useState('All Products');

    useEffect(() => {
        if (products) {
            setFilteredProducts(products);
        }
    }, [products]);

    const handleClickAddIng = () => {
        setIngredients(prev => [
            ...prev,
            {
                IngName: '',
                IngQuantity: '',
                MeasureID: ''
            }
        ]);
    };

    const handleClickRemoveIng = (index) => {
        setIngredients(prev => [
            ...prev.slice(0, index),
            ...prev.slice(index + 1)
        ]);
    };

    const handleProductSubmit = async (e) => {
        e.preventDefault();
        const product = {
            ProdName: e.target.prodName.value,
            ProdMin: parseInt(e.target.minutes?.value),
            ProdTimeCost: parseFloat(e.target.hourlyCost?.value),
            SubQuan: parseInt(e.target.subQuantity?.value),
            CatName: e.target.category?.value
        };
        const newIngredients = [];
        for (let i = 0; i < ingredients.length; i++) {
            const invId = inventory.find(item => item.DisplayName === e.target[`ingredient${i}`].value || item.InvName === e.target[`ingredient${i}`].value).InvID;
            const ingredient = {
                IngName: e.target[`ingredient${i}`]?.value,
                InvID: invId,
                IngQuantity: parseFloat(e.target[`quantity${i}`]?.value),
                MeasureID: parseInt(e.target[`measure${i}`]?.value),
            };
            newIngredients.push(ingredient);
        }
        
        let result = await upsertProduct({
            body: { 
                product,
                ingredients: newIngredients
            }
        }).unwrap();
    };
    
    const handleCategoryRequiredChecked = (e) => {
        setCategoryRequired(e.target.checked);
    };

    const handleLaborTimeRequiredChecked = (e) => {
        setLaborTimeRequired(e.target.checked);
    };

    const handleServingAmountRequiredChecked = (e) => {
        setServingAmountRequired(e.target.checked);
    };

    const handleChangeCategory = (event, newValue) => {
        const catIDs = _.map(newValue, 'CatID');
        if (catIDs.length > 0) {
            const filteredItems = _.filter(products, (item) => _.includes(catIDs, item.CatID));
            setFilteredProducts(filteredItems);
            setHeader(newValue.map(item => capitalizeFirstLetter(item.CatName)).join(', '));
        } else {
            setFilteredProducts(products);
            setHeader('All Products');
        }
    };

    if (isLoading || !measurement || !filteredProducts || !category ) {
        return <div className='text-3xl'>Loading</div>
    }

    return (
        <div className='product'>
            <form
                onSubmit={handleProductSubmit}
                className='w-full bg-gray-50 shadow rounded-lg p-5'
            >
                <div className='text-2xl font-bold inventory-input'>
                    Product Input
                </div>
                <div className='shadow bg-white px-5 py-5 my-5 rounded-lg'>
                    <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4'>
                        <TextField
                            type="text"
                            color="primary"
                            size="small"
                            fullWidth
                            id="prodName"
                            name="prodName"
                            placeholder=""
                            // value={itemName}
                            label='Product Name'
                            // onChange={e => setEmail(e.target.value)}
                            variant="outlined"
                            required
                        />
                    </div>
                    {ingredients.map((item, index) => (
                        <div key={index} className='mt-4 p-3 shadow rounded-lg'>
                            <div
                                className='float-right p-1 cursor-pointer'
                                onClick={() => handleClickRemoveIng(index)}
                            >
                                <Close 
                                    className='text-primary' 
                                    fontSize='medium'
                                    sx={{
                                        fontWeight: 'bold',
                                        strokeWidth: '2',
                                        stroke: 'rgb(100 86 226 / var(--tw-text-opacity))'
                                    }}
                                />
                            </div>
                            <div className='grid grid-cols-1 lg:grid-cols-3 gap-4'>
                                <Autocomplete
                                    disablePortal
                                    id={`ingredient${index}`}
                                    name={`ingredient${index}`}
                                    size='small'
                                    options={inventory}
                                    getOptionLabel={(option) => option.DisplayName ? option.DisplayName : option.InvName }
                                    renderInput={(params) => <TextField {...params} label="Ingredient" required />}
                                />
                                <TextField
                                    type="text"
                                    color="primary"
                                    size="small"
                                    fullWidth
                                    id={`quantity${index}`}
                                    name={`quantity${index}`}
                                    // value={itemName}
                                    label='Quantity'
                                    // onChange={e => setEmail(e.target.value)}
                                    variant="outlined"
                                    required
                                />
                                <FormControl fullWidth size='small'>
                                    <InputLabel id="measurement">Measure</InputLabel>
                                    <Select
                                        native 
                                        labelId="measurement"
                                        color="primary"
                                        id={`measure${index}`}
                                        name={`measure${index}`}
                                        label="Measure"
                                        defaultValue="" 
                                        required
                                    >
                                        <option aria-label="None" value="" />
                                        {Object.keys(measurement).map((item, index) => (
                                            <optgroup label={item}>
                                                {measurement[item].map((e, index) => (
                                                    <option value={e.MeasureID}>{e.MeasureName}</option>
                                                ))}
                                            </optgroup>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    ))}
                    <Button
                        className='bg-primary hover:bg-primary mt-8'
                        variant="contained"
                        onClick={handleClickAddIng}
                    >
                        Add Igredient
                    </Button>
                    <div className='flex flex-col sm:flex-row sm:items-center mt-4'>
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    name="categoryRequired"
                                    checked={categoryRequired}
                                    onChange={handleCategoryRequiredChecked}
                                />
                            }
                            label="Add Tag/Category"
                            className='text-nowrap'
                        />
                        {categoryRequired && (
                            <TextField
                                type="text"
                                color="primary"
                                size="small"
                                id="category"
                                name="category"
                                placeholder=""   
                                label="Tag/Category Name"
                                variant="outlined"
                            />
                        )}
                    </div>
                    <div className='flex flex-col sm:flex-row sm:items-center mt-4'>
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    name="laborTimeRequired"
                                    checked={laborTimeRequired}
                                    onChange={handleLaborTimeRequiredChecked}
                                />
                            }
                            label="Add Labor/Time Cost"
                            className='text-nowrap'
                        />
                        {laborTimeRequired && (
                            <div className='flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4'>
                                <TextField
                                    type="text"
                                    color="primary"
                                    size="small"
                                    id="minutes"
                                    fullWidth
                                    name="minutes"
                                    placeholder=""   
                                    label="Minutes"
                                    // value={itemName}
                                    // onChange={e => setEmail(e.target.value)}
                                    variant="outlined"
                                />
                                <TextField
                                    type="text"
                                    color="primary"
                                    size="small"
                                    id="hourlyCost"
                                    fullWidth
                                    name="hourlyCost"
                                    placeholder=""   
                                    label="Hourly Cost"
                                    // value={itemName}
                                    // onChange={e => setEmail(e.target.value)}
                                    variant="outlined"
                                />
                            </div>
                        )}
                    </div>
                    <div className='flex flex-col sm:flex-row sm:items-center mt-4'>
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    name="subQuantityRequired"
                                    checked={servingAmountRequired}
                                    onChange={handleServingAmountRequiredChecked}
                                />
                            }
                            label="Add Serving Amount"
                            className='text-nowrap'
                        />
                        {servingAmountRequired && (
                            <TextField
                                type="text"
                                color="primary"
                                size="small"
                                id="subQuantity"
                                name="subQuantity"
                                placeholder=""   
                                label="Serving Amount"
                                // value={itemName}
                                // onChange={e => setEmail(e.target.value)}
                                variant="outlined"
                            />
                        )}
                        <Tooltip title="If this product produces more than 1 good, you can set the output here">
                            <IconButton>
                                <HelpOutlineOutlined />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <div className=''>
                    <Button
                        className='bg-primary hover:bg-primary'
                        variant="contained"
                        type='submit'
                    >
                        Submit
                    </Button>
                </div>
            </form>
            <div className='p-5 shadow rounded-lg bg-gray-50 inventory-wrapper mt-16'>
                <div className='text-2xl font-bold mx-2'>
                    {header}
                </div>
                <div className='inv-category mt-5 mx-2 bg-white lg:w-1/3'>
                    <Autocomplete
                        // value={selectedCat}
                        onChange={handleChangeCategory}
                        multiple
                        size='small'
                        id="tags-outlined"
                        disableCloseOnSelect
                        options={category}
                        getOptionLabel={(option) => option.CatName}
                        // defaultValue={[top100Films[13]]}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.CatName}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Category / Tag"
                            />
                        )}
                    />
                </div>
                <Carousel 
                    responsive={responsive}
                    partialVisbile
                    swipeable={true}
                    arrows={true}
                >
                    {filteredProducts?.map(item => (
                        <ProductCard item={item} key={item.ProdID} />
                    ))}
                </Carousel>
            </div>
        </div>
    )
}
